export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue sur le portail digital de transmission de PV d'accidents de l’ASA Bénin"])},
  "enterYourId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer vos identifiants pour vous connecter"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant ou email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "loginButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
  "forgetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color: #C6C6CA;'>Vous avez oublié votre mot de passe ?</span> <span style='color: #4945FF; cursor: pointer;'>Réinitialiser</span>"])},
  "loginError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant/Email ou mot de passe incorrects"])},
  "fillFieldError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir tous les champs"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MENU"])},
  "dashboardMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
  "sinisterMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinistres"])},
  "reportSinisterMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PV Accidents"])},
  "policeMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissariats"])},
  "insurerMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assureurs"])},
  "statsMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiques"])},
  "compatibilityMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptabilité"])},
  "userMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
  "logoutMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
  "madeByMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color: #B8BFCE;'>Propulsé par </span><span><a href='https://www.41devs.com/' target='_blank' style='color: #FFFFFF;'>41devs</a></span>"])},
  "accountFinalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalisation du compte"])},
  "createPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un mot de passe pour finaliser la configuration de votre compte"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le mot de passe"])},
  "continuousButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
  "activityFigures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiffres d’activité"])},
  "declaredSinister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinistres déclarés"])},
  "damagedVehicles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclarations police"])},
  "insuredDeclarations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PV téléchargés"])},
  "repayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reversement à l’ASA-BENIN"])},
  "amountDueMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant dû ce mois"])},
  "searchSinister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un PV"])},
  "exportButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
  "sinisterNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº Sinistre"])},
  "pvNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° du PV"])},
  "vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicule"])},
  "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
  "declaration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclaration"])},
  "comingDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de survenance"])},
  "activityPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période d’activité"])},
  "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "startExportButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancer l’exportation"])},
  "town": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
  "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au"])},
  "filterByMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrage par mois"])},
  "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procès verbal"])},
  "downloadButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le document"])},
  "declaredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclarée par"])},
  "sinisterPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu du sinistre"])},
  "victims": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Victimes"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "createUserButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un utilisateur"])},
  "usernames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant"])},
  "completeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom complet"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôle"])},
  "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un utilisateur"])},
  "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom et prénom"])},
  "addUserButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter l’utilisateur"])},
  "declarations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclarations"])},
  "insurerToASA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assureurs à ASA-BENIN"])},
  "ASAToPolice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASA-BENIN à police"])},
  "searchInsurer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un assureur"])},
  "addCompanyButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une compagnie"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compagnie"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "administrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur"])},
  "collaborators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborateurs"])},
  "addInsurerCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une compagnie d’assurance"])},
  "enterpriseInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations d’entreprise"])},
  "addLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un logo"])},
  "addAgentPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une photo de l’entreprise"])},
  "saveButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "searchPolice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un commissariat"])},
  "addPolice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un commissariat"])},
  "addInsurer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une compagnie"])},
  "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "adressBill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de facturation"])},
  "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banque"])},
  "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur"])},
  "collaborator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborateurs"])},
  "intitul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intitulé"])},
  "arond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrondissements"])},
  "insurerForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations d'entreprise"])},
  "policeForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations"])},
  "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte de connexion"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "addPic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une photo de l’agent (facultatif)"])},
  "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poste"])},
  "declaredReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PV déclarés"])},
  "existingUsernameError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant existant"])},
  "successCreateAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte créé avec succès. Un lien vous a été envoyé par mail pour définir votre mot de passe."])},
  "fillPasswordError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir tous les champs"])},
  "passwordError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe et confirmation non conformes"])},
  "characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 caractères"])},
  "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contient un nombre"])},
  "uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contient une majuscule"])},
  "specialCharacters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contient un caractère spécial"])},
  "noUserAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun utilisateur ajouté"])},
  "leaveApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir vous déconnecter ?"])},
  "passwordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialisation de mot de passe"])},
  "enterUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer votre identifiant pour finaliser la réinitialisation de mot de passe"])},
  "enterPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer un nouveau mot de passe pour finaliser la réinitialisation"])},
  "noInsuranceAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune compagnie d'assurance ajoutée"])},
  "noPoliceAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun commissariat de police ajouté"])},
  "noSinisterAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune déclaration de sinistre ajoutée"])},
  "searchMinutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un PV"])},
  "sinisterComingDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de survenance du sinistre"])},
  "minutesNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº du PV"])},
  "sinistersNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº du sinistre"])},
  "matriculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immatriculation"])},
  "startSearchButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancer la recherche"])},
  "policyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du commissariat"])},
  "downloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargé"])},
  "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "noNotificationAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune notification"])},
  "insuredName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'assuré"])},
  "addingDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'ajout"])},
  "policiesUnity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unités de police"])},
  "insured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assuré"])},
  "processVerbal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procès verbaux"])},
  "dueAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant dû"])},
  "victimsNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nbre de victimes"])}
}