import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

const store = createStore({
  plugins: [createPersistedState()],
  state: {
    token: "",
    isAuth: false,
    isRedirect: true,
  },
  getters: {
    loginToken: (state) => {
      return state.token;
    },
    loginAuth: (state) => {
      return state.isAuth;
    },
    loginRedirect: (state) => {
      return state.isRedirect;
    },
  },
  mutations: {
    UPDATE_TOKEN(state, token) {
      state.token = token;
    },
    UPDATE_LOGOUT(state) {
      state.token = "";
      state.isAuth = false;
      state.isRedirect = true;
    },
    UPDATE_IS_AUTH(state, auth) {
      state.isAuth = auth;
    },
    UPDATE_IS_REDIRECT(state, redirect) {
      state.isRedirect = redirect;
    },
  },
  actions: {
    CREATE_TOKEN({ commit }, toke) {
      commit("UPDATE_TOKEN", toke);
    },
    CREATE_LOGOUT({ commit }, dec) {
      commit("UPDATE_LOGOUT", dec);
    },
    CREATE_IS_AUTH({ commit }, aux) {
      commit("UPDATE_IS_AUTH", aux);
    },
    CREATE_IS_REDIRECT({ commit }, re) {
      commit("UPDATE_IS_REDIRECT", re);
    },
  },
  modules: {},
});
export default store;
